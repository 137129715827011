import React from "react";
import { isMobile } from "../../functions/theme";

const HomeSecondaryTitle = (props: { 
  smallTitle: string,
  mainTitle: string,
}) => {

  const _isMobile: boolean = isMobile();

  return (

    //3xl:mb-44 md:my-20 my-5
    <div className="flex flex-col w-full ">
      <div className="flex items-center space-x-12">
        {props?.smallTitle && props.smallTitle == "THE COMPANY" && !_isMobile && <div className="flex justify-center text-xs font-medium lg:text-xl md:text-lg text-grey-light">01/03</div>}
        {props?.smallTitle && props.smallTitle == "PROJECTS" && !_isMobile && <div className="flex justify-center text-xs font-medium lg:text-xl md:text-lg text-grey-light">02/03</div>}
        {props?.smallTitle && props.smallTitle == "EXPERTISE" && !_isMobile && <div className="flex justify-center text-xs font-medium lg:text-xl md:text-lg text-grey-light">03/03</div>}
        <div className="text-xs font-medium lg:text-2xl md:text-lg">{props?.smallTitle && props.smallTitle}</div>
      </div>
      <div className="mt-10 text-3xl font-medium whitespace-pre-wrap lg:text-6xl md:text-5xl">{props?.mainTitle && props.mainTitle}</div>
    </div>

  )

}

export default HomeSecondaryTitle;
