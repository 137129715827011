import { graphql, Link, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as React from 'react'
import { useState } from 'react'
import DefaultAppView from '../app-view/default-app-view'
import Footer from '../components/footer'
import Header from '../components/header'
import { SEO } from '../components/seo'
import { getTheme } from '../functions/theme'
import HomeSecondaryTitle from './home-components/home-secondary-title'

const Projects = () => {

    const staticQuery: any = useStaticQuery(el);

    const currenteTheme: 'light' | 'dark' = getTheme();
    //console.log(staticQuery)

    const _projects: any[] = staticQuery.allStrapiProject.edges.map((el: any) => {
        return {
            nodeImage: el.node.picture.localFile,
            priority: el.node.priority,
            placeholder: el.node.title,
            id: el.node.id
        };
    }).sort((a: any, b: any) => a.priority && b.priority && (a.priority - b.priority))

    const [projects, setProjects] = useState<any[]>(_projects);
    
    return (
        <DefaultAppView>
            <Header currentPageTheme={currenteTheme}></Header>
            <div className="px-5 reverse-bg-zone-trigger lg:px-36" id="reverse-bg-zone-trigger">
                <div className='pt-28'>
                    <HomeSecondaryTitle smallTitle="PROJECTS" mainTitle=''></HomeSecondaryTitle>
                </div>
            </div>
            <div className="flex flex-col min-h-screen px-5 lg:px-32 lg:pt-10 xl:pb-80">
                <div className='w-full border-black lg:pb-40 lg:border-b'>
                    <div className='w-full text-3xl font-semibold xl:text-7xl md:w-11/12 3xl:w-4/5 3xl:text-8xl'>Some of the best projects we have worked on</div>
                </div>
                {/* <div className='p-10 border-b-2 border-black'>
                    <div className='flex justify-end text-base font-medium'>
                        <span className='px-6 py-4 border-2 border-black rounded-full'>2022</span>
                    </div>
                </div> */}
                <div className='flex flex-wrap pt-16 space-y-7 lg:space-y-0 lg:space-x-16'>
                    {projects.map((image: any) => {
                        const _image: IGatsbyImageData = image?.nodeImage && getImage(image.nodeImage);
                        return (
                            image &&
                            <div className="w-full h-full md:w-[45%]" key={image.id}>
                                <div className='pb-3 lg:pb-9'>
                                    <Link to="/project/projects-detail"> <GatsbyImage image={_image} alt={image.placeholder} className="flex-1 w-full h-full btn-like " /></Link></div>
                                <span className='text-2xl font-medium lg:font-semibold lg:text-3xl'>{image.placeholder}</span>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
            <Footer></Footer>
        </DefaultAppView>
    )
}

export const el = graphql`
    query {
      allStrapiProject {
        edges {
          node {
            id
            title
            priority
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
`;

export default Projects

export const Head = () => (
    <SEO title='Cardinal - Supersecret Projects'/>
)